.text h2 {
  font-family: "MontSemiBold";
  margin-bottom: 1rem;
}

.text h3 {
  font-family: "MontSemiBold";
  margin-bottom: 0.25rem;
}

.text p {
  margin-bottom: 0.75rem;
}
