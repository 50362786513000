.promo {
  background: url("./promoBg.svg") no-repeat right center;
  width: 100%;
  height: 100%;
  padding: 6.25rem 5rem 4rem;
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 1rem;
  font-size: 2.5rem;
  font-family: "MontSemiBold";
}

.title b {
  font-family: "MontHeavy";
  display: inline-block;
  position: relative;
  z-index: 1;
}

.title b span {
  position: relative;
  z-index: 1;
}

.title b:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1rem;
  background: #a9caff;
  z-index: 0;
  transform: translateY(-30%);
}

.subtitle {
  font-size: 1.5rem;
}

.bottom {
  margin-top: auto;
}

.avatar {
  width: 5rem;
  height: 5rem;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar img {
  object-fit: contain;
}

.text {
  margin: 1.5rem 0 0.75rem;
  font-size: 1.125rem;
}

.epilog {
  font-size: 0.875rem;
}

.bottom b {
  font-family: "MontBold";
}
