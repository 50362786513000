.counts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  margin-bottom: 24px;
}

.controls {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  & > span {
    color: #284775;
    font-family: "MontBold";
    font-size: 24px;
  }
}
