.title {
  color: #284775;
  font-family: "MontBold";
  font-size: 24px;
  margin-bottom: 24px;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 3rem - 129px);
  margin-bottom: -24px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  position: relative;
  padding-bottom: 100px;
}

.bottom {
  margin-top: auto;
  display: flex;
  padding: 12px 24px;
  gap: 16px;
  border-top: 1px solid #e7e7e7;
  position: fixed;
  bottom: 0;
  left: 16rem;
  width: 100%;
  background-color: #fff;
}

.cols {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 24px;
}

.inner {
  display: grid;
  gap: 12px;
}

.smallBlock {
  align-self: flex-start;
}

.form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  row-gap: 12px;
}
