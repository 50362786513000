.slider {
  width: 100%;
  min-width: 300px;
}

.track {
  background-color: #cce0ff;
  height: 6px;
  border-radius: 8px;
}

.track:first-child {
  background-color: #2f6cce;
}

.thumb {
  cursor: grab;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  border: 6px solid #fff;
  background: #2f6cce;
  box-shadow: 0px 1px 7px 0px rgba(0, 0, 0, 0.31);
  font-size: 0;
  transform: /* translateX(-50%) */ translateY(calc(-50% + 3px));
}

.thumb:focus-visible {
  outline: none;
}

.wrapper {
  width: 100%;
  display: flex;
  margin-bottom: 24px;
}

.tip {
  height: 100%;
  font-size: 0.75rem;
  display: flex;
  align-items: flex-end;
  position: absolute;
  left: 0;
  bottom: 0;
  font-family: "MontSemiBold";
}

.right {
  left: auto;
  right: 0;
}

.content {
  position: relative;
}
