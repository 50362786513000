.wrapper {
  display: flex;
  padding: 24px 16px;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #e3e3e3;

  &:last-child {
    border-bottom: none;
  }
}

.text {
  color: #284775;
  font-family: "MontBold";
  font-size: 16px;
}

.left {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content {
  padding: 24px;
}

.dropzone {
  border-radius: 4px;
  border: 2px dashed #2f6cce;
  background: #eff5ff;
  padding: 32px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  text-align: center;
  cursor: pointer;

  p {
    color: #284775;
    font-family: "MontSemiBold";
  }

  span {
    color: #2f6cce;
    text-decoration-line: underline;
  }
}

.hint {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-top: 12px;
  color: #5072a5;
  font-size: 12px;
}

.file {
  margin-top: 16px;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
  width: 100%;
  display: flex;
  align-items: center;

  span {
    margin-left: 6px;
  }
}

.fileDelete {
  cursor: pointer;
  margin-left: auto;
  color: #8a8a8a;

  &:hover {
    color: #284775;
  }

  svg {
    width: 23px;
    height: 23px;
  }
}

.input {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  pointer-events: none;
}
