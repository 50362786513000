.counts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 24px;
  gap: 24px;
}

.controls {
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  justify-content: end;
}
