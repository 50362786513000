.input {
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  padding: 10px 12px 10px 40px;
  background: url("./search.svg") no-repeat left 12px center;
}

.input::placeholder {
  color: #c3c9d6;
  font-family: "MontRegular";
}
