.block {
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
}

.paddings {
  padding: 1rem;
}

.header {
  padding: 1rem;
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 1.125rem;
  font-family: "MontBold";
}

.subtitle {
  color: #5072a5;
  font-size: 0.875rem;
}
