.tabs {
  display: flex;
  margin-bottom: 1rem;
}

.tab {
  display: flex;
  padding: 12px 32px;
  align-items: flex-start;
  gap: 8px;
  font-family: "MontSemiBold";
  cursor: pointer;
}

.active {
  border-bottom: 3px solid #284775;
}
