.searchBar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.searchBar input {
  width: 440px;
  margin-right: 1rem;
}
