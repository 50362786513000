.container {
  position: relative;
  height: var(--input-height);
  width: 100%;
  border-radius: 4px;
  background: #a5c0f2;
  overflow: hidden;
}

.bar {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #6291ef;
}

.percents {
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.125rem;
  font-family: "MontBold";
}
