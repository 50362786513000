.link {
  display: flex;
  padding: 0.5rem 0.75rem;
  align-items: center;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.link:hover {
  background: #e4efff;
}

.text {
  margin-top: 3px;
}

.active {
  background: #e4efff;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #99b4de;
}

.active .icon {
  color: #5476aa;
}

.link:hover .icon {
  color: #5476aa;
}

.chevrone {
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.count {
  margin-left: auto;
  border-radius: 24px;
  background: #284775;
  padding: 4px 8px;
  color: #e4efff;
  font-size: 12px;
  font-family: "MontSemiBold";
}

.child {
  padding: 8px 32px 0;
  font-size: 13px;
  font-family: "MontSemiBold";
  white-space: nowrap;
}

.activeChild {
  font-family: "MontBold";
}
