.btn {
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  font-family: "MontSemiBold";
  cursor: pointer;
  white-space: nowrap;
}

.wide {
  color: var(--white);
  background-color: var(--link-color);
  padding: 11px;
  border-radius: 4px;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 18px;
}

.primary {
  border-radius: 4px;
  background: #2f6cce;
  color: #fff;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
}

.ghost {
  border-radius: 4px;
  background: transparent;
  color: #284775;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
}

.light {
  border-radius: 4px;
  background: #e4efff;
  color: #284775;
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 36px;
}

.pending {
  pointer-events: none;
  opacity: 0.7;
}
