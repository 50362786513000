@font-face {
  font-family: "MontLight";
  src: url("./Mont-Light.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: "MontRegular";
  src: local("MontRegular"), local("Mont Regular"),
    url("./Mont-Regular.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: "MontSemiBold";
  src: url(./Mont-SemiBold.otf) format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: "MontBold";
  src: url("./Mont-Bold.otf") format("opentype");
  font-display: fallback;
}

@font-face {
  font-family: "MontHeavy";
  src: local("MontHeavy"), local("Mont Heavy"),
    url(./Mont-Heavy.otf) format("opentype");
  font-display: fallback;
}
