.row3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
}

.grid {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;

  button {
    align-self: flex-start;
    margin-top: -16px;
  }
}

.bottom {
  margin-top: auto;
  display: flex;
  padding: 12px 24px;
  gap: 16px;
  border-top: 1px solid #e7e7e7;
  position: fixed;
  bottom: 0;
  left: 16rem;
  width: 100%;
  background-color: #fff;
}
