.tabs {
  max-width: calc(100% + 24px);
  margin-right: -24px;
  overflow: auto;
  white-space: nowrap;
  margin-bottom: 24px;

  &::-webkit-scrollbar {
    display: none;
  }
}
