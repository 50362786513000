.shadow {
  background-color: transparent;
  justify-content: end;
}

.modal {
  box-shadow: 0px 4px 26px 0px rgba(0, 0, 0, 0.17);
  border-radius: 0;
  height: 100vh;
  width: 80vw;
  overflow-y: auto;
}

.header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2;
  display: flex;
  padding: 1rem 1.5rem;
  gap: 1rem;
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #dedede;
}

.title {
  font-size: 1.5rem;
  font-family: "MontBold";
}

.subtitle {
  color: #5072a5;
}

.cols {
  padding: 1rem;
  display: grid;
  /* grid-template-columns: calc(66% - 0.5rem) calc(34% - 0.5rem); */
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
}

.col {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btnAndTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0;
  align-items: center;
  color: #5072a5;
}

.btnAndTitle b {
  font-family: "MontBold";
}

.btnAndTitle:not(:last-child) {
  padding-bottom: 0.75rem;
  padding-top: 0;
  border-bottom: 1px solid #e3e3e3;
}

.amounts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.amountDescr {
  color: #5072a5;
  font-family: "MontSemiBold";
}

.amountNumber {
  font-size: 2rem;
  font-family: "MontBold";
}

.addBtn {
  color: #2f6cce;
}

.feeItem {
  padding: 0.75rem 0;
  font-size: 0.875rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
}

.feeToggle {
  padding: 0.375rem 0;
}

.feeItem span {
  color: #5072a5;
}

.feeItem b {
  color: #284775;
  font-family: "MontBold";
}

.feeItem:first-child {
  padding-top: 0;
}

.feeItem:last-child {
  padding-bottom: 0;
}

.divider {
  width: 100%;
  background: #e3e3e3;
  height: 1px;
}

.dividerBold {
  background: #284775;
}

.blockHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: "MontBold";
  font-size: 1.125rem;
}

.editBtn {
  cursor: pointer;
}
