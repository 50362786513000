.dropdown {
  position: relative;
  width: 100%;
}

.dropdownPlaceholder {
  padding: 8px 12px;
  border: 1px solid #d6d6d6;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  transition: 0.3s;

  &.opened {
    .arrow {
      transform: rotate(-180deg);
      transition: 0.3s;
    }
  }

  &.primary {
    border: 1px solid rgba(109, 211, 56, 0.5);
  }

  &.small {
    padding: 7px 14px;
    border-radius: 10px;
  }

  /* &.disabled {
    color: var(--color-gray-darker);
    cursor: not-allowed;
    border: 1px solid var(--color-foreground-gray-darker);
  }

  &:not(.disabled):hover {
    background: var(--color-foreground-gray);
    transition: 0.3s;
  } */
}

.placeholder {
  display: flex;
  align-items: center;
  column-gap: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.arrow {
  line-height: 0;
  transition: 0.3s;
}

.overlay {
  list-style-type: none;
  position: absolute;
  z-index: 10;
  min-width: min-content;
  right: 0;
  margin: 0;
  background-color: #fff;
  width: 100%;
  padding: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: 0.1s;
  border: 1px solid #d6d6d6;
  //box-shadow: 0px 4px 12px var(--color-foreground-gray-darker);
  max-height: 250px;
  overflow-y: auto;

  &.center {
    right: auto;
    left: -50%;
  }

  &.opened {
    transform-origin: top;
    transform: scaleY(1);
    transition: 0.1s;

    &.center {
      transform: scaleY(1) translateX(-12px);
      left: -50%;
    }
  }
}

.item {
  padding: 10px 12px;
  display: flex;
  align-items: center;
  column-gap: 6px;
  cursor: pointer;
  transition: 0.3s;
  line-height: 24px;

  svg {
    color: var(--color-gray-darker);
  }
}

.item:hover {
  background-color: var(--color-foreground-gray);
  transition: 0.3s;
}

.label {
  font-weight: 500;
  color: #718992;
  line-height: 24px;
  margin-bottom: 5px;
}

.placeholderText {
  color: rgba(0, 0, 0, 0.25);
}

.disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
