.bottom {
  display: flex;
  width: 100%;
  padding: 12px 24px;
  border-top: 1px solid #e7e7e7;
  justify-content: end;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.backBtn {
  margin-right: auto;
}

.backBtn svg {
  transform: scaleX(-1);
}
