.sidebar {
  padding: 1.5rem;
  border-right: 1px solid #e7e7e7;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sidebar::-webkit-scrollbar {
  display: none;
}

.logo {
  margin-bottom: 2.5rem;
}

.subtitle {
  color: #9da4ae;
  font-family: "MontBold";
  padding: 0.5rem;
  font-size: 0.75rem;
}
