.sidebar {
  padding: 1.5rem;
  border-right: 1px solid #e7e7e7;
  height: 100%;
}

.modal {
  width: 70vw;
  height: 80vh;
}

.grid {
  display: grid;
  grid-template-columns: 3fr 7fr;
  grid-template-rows: 100%;
  height: 100%;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem 1.5rem 1rem;
  gap: 1rem;
}

.close {
  cursor: pointer;
}

.title {
  font-size: 1.5rem;
  font-family: "MontBold";
}

.subtitle {
  color: #5072a5;
}

.content {
  padding: 0 1.5rem 5.5rem;
  flex-grow: 2;
  overflow-y: auto;
}

.content::-webkit-scrollbar {
  display: none;
}

.right {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}
