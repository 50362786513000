.container {
  position: relative;
  width: 32px;
  height: 32px;
}

.openedContainer {
  z-index: 3;
}

.btn {
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.opened {
  background: #f5f9ff;
}

.list {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.21);
  padding: 12px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  min-width: 160px;
}

.item {
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 8px;
  cursor: pointer;

  &:hover {
    border-radius: 4px;
    background: #e8f1ff;
  }

  svg {
    width: 20px;
    height: 20px;
    color: #99b4de;
  }
}
