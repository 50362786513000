.msg {
  font-size: 0.687rem;
  margin: 0.1rem 0;
  color: #ff0558;
  position: absolute;
  bottom: -1rem;
  white-space: nowrap;
}

.static {
  position: static;
}
