.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inviteText {
  font-family: "MontSemiBold";
  color: #626262;
  opacity: 0.8;
  margin-bottom: 8px;
}
