.page {
  width: 100%;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 3fr 4fr;
}

.left {
  min-height: 100vh;
  padding: 2rem;
}

.right {
  background: var(--auth-bg);
  min-height: 100vh;
}
