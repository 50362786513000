.mainHeader {
  display: flex;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  //color: var(--color-black);
  grid-gap: 10px;
  align-items: center;
  margin-bottom: 24px;
  padding-top: 20px;
}

.container {
  width: 100%;
  //overflow-x: auto;
}

.table {
  border-spacing: 0;
  min-width: 500px;
  width: 100%;
  table-layout: fixed;

  tr:hover {
    cursor: pointer;
    td {
      //background: var(--color-foreground-gray-darker);
      transition: 0.3s;
    }
  }

  tr td {
    transition: 0.3s;
  }

  tr th,
  tr td {
    //border-right: 1px solid #e9f1f5;
    border-bottom: 1px solid #e9f1f5;
  }

  tr th:first-child,
  tr td:first-child {
    //border-left: 1px solid #e9f1f5;
  }

  tr th:first-child,
  tr td:first-child {
    //border-left: 1px solid #e9f1f5;
  }

  tr th {
    background: #eee;
    border-top: solid 1px #e9f1f5;

    border-bottom: 1px solid #d4e2f8;
    background: #f5f9ff;
  }
}

.header {
  //color: var(--color-gray-darker);
  font-size: 16px;
  line-height: 24px;

  tr th {
    font-weight: 500;
    white-space: nowrap;
    //background: var(--color-foreground-gray);
  }
}

.cell {
  text-align: left;
  padding: 10px 20px;
  white-space: nowrap;
}

.bodyCell {
  //color: var(--color-black);
}

.footer td {
  //background: var(--color-foreground-gray);
}
