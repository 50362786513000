.root {
  width: 100%;
  & :global(.MuiInputBase-input) {
    background-color: var(--color-background-tabs);
    font-size: var(--font-size-sm);
    font-weight: var(--font-weight-base);
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: var(--input-height);
  }

  & :global(.MuiFormControl-root) {
    width: 100%;
  }

  & i {
    font-size: 1.25rem;
  }

  & :global(.Mui-error .MuiOutlinedInput-notchedOutline) {
    border-color: #d6d6d6 !important;
  }

  & :global(.Mui-focused .MuiOutlinedInput-notchedOutline) {
    border-color: #1976d2 !important;
  }
}

.value {
  display: flex;
  gap: 1rem;
  align-items: center;
}
