.input {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  background: #fff;
  padding: 10px 12px;
  height: var(--input-height);
}

.input::placeholder {
  color: #c3c9d6;
  font-family: "MontRegular";
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.postFix {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #fff;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}
