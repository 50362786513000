.card {
  min-height: 52px;
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid #e7e7e7;
  background: #fff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.07);
  padding: 8px 12px;
  gap: 12px;
  font-size: 0.875rem;
  color: #626262;
  justify-content: space-between;
}

.name {
  font-family: "MontBold";
}

.delete {
  cursor: pointer;
}

.add {
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #99b4de;
  background: #f5f9ff;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  color: #99b4de;
}

.modal {
  width: 60vw;
}

.modalContent {
  padding: 1.5rem;
  display: grid;
  gap: 1.5rem;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.75rem;
}

.gridFormMain {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 0.75rem;
  grid-template-areas:
    "a a a a a a a a b b b b b b b b c c c c c c c c"
    "d d d d d d d d e e e e e e e e f f f f f f f f"
    "g g g g g g h h h h h h i i i i i i j j j j j j ";
}

.gridFormMain > div:nth-child(1) {
  grid-area: a;
}

.gridFormMain > div:nth-child(2) {
  grid-area: b;
}

.gridFormMain > div:nth-child(3) {
  grid-area: c;
}

.gridFormMain > div:nth-child(4) {
  grid-area: d;
}

.gridFormMain > div:nth-child(5) {
  grid-area: e;
}

.gridFormMain > div:nth-child(6) {
  grid-area: f;
}

.gridFormMain > div:nth-child(7) {
  grid-area: g;
}

.gridFormMain > div:nth-child(8) {
  grid-area: h;
}

.gridFormMain > div:nth-child(9) {
  grid-area: i;
}

.gridFormMain > div:nth-child(10) {
  grid-area: j;
}
