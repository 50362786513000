.title {
  font-family: "MontBold";
  font-size: 0.875rem;
  color: #5072a5;
}

.step {
  color: #5072a5;
  height: 4rem;
  display: flex;
  padding: 0 0.75rem;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  text-transform: capitalize;

  &:before {
    box-sizing: border-box;
    content: "";
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 4px solid #cce0ff;
    position: relative;
    z-index: 2;
  }

  &::after {
    content: "";
    position: absolute;
    background: #cce0ff;
    width: 1px;
    height: 44px;
    bottom: 0;
    display: block;
    left: calc(0.75rem + 12px);
    transform: translateY(50%);
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.active {
  color: #284775;
  font-family: "MontBold";
  &::before {
    border: 8px solid #2f6cce;
  }
}

.completed {
  &::before {
    border: none;
    background-color: #2f6cce;
    background-image: url("./check.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}
