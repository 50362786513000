.searchBar {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.searchBar input {
  width: 440px;
  margin-right: 1rem;
}

.cols {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1.5rem;
}

.list {
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 0.5rem;
  background: #f6f6f6;
  min-height: 100%;
  padding-top: 1rem;
}

.colHeader {
  font-family: "MontBold";
  padding-left: calc(10px + 0.5rem);
  position: relative;
  margin-left: 0.25rem;

  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #91a746;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
}

.colHeaderInactive {
  margin-top: 1rem;
  &:before {
    background: #c54b36;
  }
}

//////

.counts {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 1.5rem;
}

.count {
  display: flex;
  gap: 1rem;
}

.countIcon {
  border-radius: 8px;
  background: #f5f9ff;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #99b4de;
}

.countTitle {
  color: #5072a5;
  font-family: "MontSemiBold";
}

.countSibtitle {
  font-size: 2rem;
  font-family: "MontBold";
}

.add {
  margin-left: auto;
}
