.container {
  display: grid;
  gap: 24px;
  grid-template-columns: 7fr 3fr;

  & > div {
    align-self: flex-start;
  }
}

.cell {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 14px;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  & > span {
    color: #5072a5;
    font-size: 14px;
  }

  b {
    color: #284775;
    font-family: "MontBold";
    font-size: 14px;
  }

  a b {
    color: #2f6cce;
  }
}
