.flex {
  display: flex;
  gap: 1rem;
}

.icon {
  border-radius: 8px;
  background: #f5f9ff;
  height: 56px;
  width: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #99b4de;
}

.title {
  color: #5072a5;
  font-family: "MontSemiBold";
}

.value {
  font-size: 2rem;
  font-family: "MontBold";
}
