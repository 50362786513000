.wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  font-size: inherit;
  width: 100%;
  user-select: none;

  &:not(.disabled) {
    cursor: pointer;
  }

  &.error .icon {
    // border-color: var(--color-red-darker);
  }
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  min-width: 20px;
  height: 20px;
  font-size: 10px;
  line-height: 10px;
  border-radius: 4px;
  border: 2px solid #7b8682;

  &:before {
    opacity: 0;
  }

  & svg {
    display: none;
  }

  .checkbox:checked ~ & {
    // color: var(--color-white);

    & svg {
      display: block;
    }

    &:before {
      opacity: 1;
    }
  }

  .checkbox:disabled ~ & {
    opacity: 0.3 !important;

    &:before {
      opacity: 1;
    }

    .tertiary & {
      background: transparent;

      &:before {
        display: none;
      }
    }
  }
}

.checkbox {
  display: none;
}

.label {
  display: block;
  margin-left: 12px;

  .tertiary & {
    color: var(--color-white);
  }

  .tertiary .checkbox:disabled ~ & {
    opacity: 0.4;
  }
}
