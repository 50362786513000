.rnc__notification-item {
  border-radius: 8px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #c54b36;
  background-image: url("./fail.svg");
  background-repeat: no-repeat;
  border-left: none;
  background-position: left 1rem center;
  padding-left: 2.5rem;
  padding-top: 10px;
  padding-bottom: 10px;
}
.rnc__notification-item--success {
  background-color: #91a746;
  background-image: url("./success.svg");
}

.rnc__notification-timer {
  background-color: transparent !important;
  width: 1.5rem;
  height: 1.5rem;
  background-image: url("./close.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  margin-top: 0;
  transform: translateY(-50%);
}

.rnc__notification-timer > * {
  display: none;
}

.rnc__notification-title {
  display: none;
}

.rnc__notification-container--top-right > div {
  width: auto !important;
  min-width: 325px !important;
}
