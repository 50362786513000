.controls {
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  justify-content: flex-end;
}

.content {
  display: grid;
  grid-template-columns: 7fr 3fr;
  gap: 24px;
}

.table {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
}

.cell {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e3e3e3;
  font-size: 14px;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  &:first-child {
    padding-top: 0;
  }

  & > span {
    color: #5072a5;
    font-size: 14px;
  }

  b {
    color: #284775;
    font-family: "MontBold";
    font-size: 14px;
  }

  a b {
    color: #2f6cce;
  }
}

.avatar {
  width: 96px;
  height: 96px;
  overflow: hidden;
  border-radius: 50%;
  margin: 16px auto;

  img {
    object-fit: cover;
  }
}

.viewManager {
  flex-basis: 100%;
  text-align: center;
  color: #2f6cce;
  font-family: "MontSemiBold";
  cursor: pointer;
}

.secondCol {
  display: grid;
  gap: 24px;
}

.mainBlock {
  align-self: flex-start;
}
