.individualHeader {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
}

.avatar {
  overflow: hidden;
  width: 88px;
  height: 88px;
  border-radius: 50%;
  background: #e4efff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar svg {
  width: 50%;
  height: 50%;
}

.avatar img {
  width: 100%;
  object-fit: cover;
}

.name {
  font-size: 1.25rem;
  font-family: "MontSemiBold";
}

.email {
  color: #5072a5;
  font-size: 0.875rem;
}

.btns {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.title {
  font-family: "MontBold";
  font-size: 0.875rem;
  color: #5072a5;
}

.step {
  height: var(--input-height);
  display: flex;
  align-items: center;
  padding: 0 0.75rem;
  border-radius: 0.5rem;
  gap: 0.5rem;
  font-family: "MontSemiBold";
  cursor: pointer;
}

.step svg {
  color: #99b4de;
}

.step.active {
  background: #e4efff;
}

.step.active svg {
  color: #5476aa;
}
