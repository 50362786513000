.shadow {
  background: rgba(25, 37, 56, 0.8);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  border-radius: 16px;
  background: #fff;
  min-width: 500px;
  min-height: 300px;
  overflow: hidden;
}
