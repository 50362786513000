.roundBackground {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.middle {
    height: 48px;
    width: 48px;
}

.big {
    height: 62px;
    width: 62px;
}
