.headline {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "MontBold";
  font-size: 1.5rem;
  padding: 1.5rem;
  padding-bottom: 1rem;
}

.close {
  cursor: pointer;
}

.modal {
  display: flex;
}

.bottom {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  border-top: 1px solid #e7e7e7;
}

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  position: relative;
  padding-bottom: 4rem;
}
