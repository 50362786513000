.wrapper {
  display: flex;
  grid-auto-flow: column;
  align-items: center;
  cursor: pointer;
}

.label,
.preLabel {
  white-space: nowrap;
}

.label {
  margin-left: 8px;
}

.preLabel {
  margin-right: 8px;

  .checkbox:not(:checked):not(:disabled) ~ & {
    color: #fff;
  }
}

.toggle {
  display: inline-flex;
  position: relative;
  background: #eee;
  border: 1px solid #ddd;
  height: 14px;
  width: 26px;
  border-radius: 10px;
  box-sizing: content-box;

  &:after {
    content: "";
    position: absolute;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 7px;
    left: 7px;
    transform: translate(-50%, -50%);
  }

  .checkbox:checked ~ & {
    background-color: #2f6cce;

    &:after {
      background-color: #fff;
      transform: translate(calc(-50% + 12px), -50%);
    }
  }

  .checkbox:disabled ~ & {
    background-color: #212536;

    &:after {
      background-color: #161a28;
    }
  }
}

.checkbox {
  display: none;
}
