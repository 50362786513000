.container {
  display: grid;
  padding: 0.5rem;
  align-items: center;
  gap: 0.75rem;
  margin-top: auto;
  cursor: pointer;
  position: relative;
  grid-template-columns: 40px 1fr 20px;
  width: 100%;
}

.names {
  overflow: hidden;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  object-fit: cover;
}

.avatarHolder {
  background-color: #e4efff;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  font-family: "MontBold";
  max-width: 12rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email {
  color: #5072a5;
  font-size: 0.75rem;
}

.trigger {
  margin-left: auto;
}

.popup {
  position: absolute;
  right: 0;
  width: 100%;
  height: 3rem;
  background-color: #fff;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  top: 0;
  border-radius: 0.5rem;
  font-family: "MontBold";
  display: flex;
  align-items: center;
  padding: 1rem;
}

.loading {
  color: transparent;
}

.loading > div {
  background-color: #e4efff;
  border-radius: 4px;
}
