.title {
  font-family: "MontSemiBold";
  font-size: 26px;
  margin-bottom: 1rem;
  margin-top: 0;
}

.subtitle {
  font-size: 20px;
  font-family: "MontSemiBold";
}

.wrapper {
  padding: 0 4.5rem;
}
