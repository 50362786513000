.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
}

.block {
  padding: 1rem 1rem 0.75rem;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  display: grid;
  gap: 1rem;
}

.block button {
  width: fit-content;
}

.title {
  color: #284775;
  font-size: 18px;
  font-family: "MontBold";
}

.subtitle {
  color: #5072a5;
  font-size: 0.875rem;
}
