.template {
  height: 100vh;
  display: grid;
  grid-template-columns: 16rem 1fr;
  overflow: hidden;
}

.content {
  height: 100%;
  overflow-y: auto;
  padding: 1.5rem;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.5rem;
}

.title {
  font-family: "MontHeavy";
  font-size: 2rem;
}

.subtitle {
  color: #5072a5;
  font-size: 1rem;
}
