.header {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.content a span {
  text-decoration: underline;
  color: var(--link-color);
}

.logo {
  color: var(--logo-color);
  font-size: 28px;
  font-family: "MontHeavy";
}
