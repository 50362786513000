.defaultHeight {
  height: 2.64rem;
  display: flex;
  align-items: center;
}

.container {
  width: 100%;
  position: relative;
}

.content {
  width: 100%;
}

.error {
  bottom: -1rem;
}
