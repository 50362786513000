.block {
  border-radius: 8px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.05);
}

.block:not(:last-child) {
  margin-bottom: 1rem;
}

.part {
  padding: 1rem 1rem 0.75rem;
  border-bottom: 1px solid #ddd;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.part:last-child {
  border-bottom: none;
}

.title {
  font-family: "MontBold";
  font-size: 1.125rem;
}

.subtitle {
  font-size: 0.875rem;
  color: #5072a5;
}

.row2items {
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 0.75rem;
}

.row3items {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 0.75rem;
}

.row2equals {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.75rem;
}

.gridForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.75rem;
}

.gridFormMain {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  gap: 0.75rem;
  grid-template-areas:
    "a a a a a a a a b b b b b b b b c c c c c c c c"
    "d d d d d d d d e e e e e e e e f f f f f f f f"
    "g g g g g g h h h h h h i i i i i i j j j j j j ";
}

.gridFormMain > div:nth-child(1) {
  grid-area: a;
}

.gridFormMain > div:nth-child(2) {
  grid-area: b;
}

.gridFormMain > div:nth-child(3) {
  grid-area: c;
}

.gridFormMain > div:nth-child(4) {
  grid-area: d;
}

.gridFormMain > div:nth-child(5) {
  grid-area: e;
}

.gridFormMain > div:nth-child(6) {
  grid-area: f;
}

.gridFormMain > div:nth-child(7) {
  grid-area: g;
}

.gridFormMain > div:nth-child(8) {
  grid-area: h;
}

.gridFormMain > div:nth-child(9) {
  grid-area: i;
}

.gridFormMain > div:nth-child(10) {
  grid-area: j;
}

.checkbox * {
  font-size: 0.875rem !important;
}

.checkbox > * {
  align-items: flex-start;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.delete {
  cursor: pointer;
}
