.borders {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 6px;
  align-items: flex-start;
  border: 1px solid #d6d6d6;
  min-height: var(--input-height);
}

.btn {
  margin-left: auto;
  color: white;
  background-color: #2f6cce;
  font-size: 0.75rem;
  padding: 6px 8px;
  border-radius: 5px;
  cursor: pointer;
  white-space: nowrap;
}

.input {
  position: absolute;
  pointer-events: none;
  opacity: 0;
  z-index: -1;
}

.file {
  padding: 6px 8px;
  border-radius: 5px;
  background: #e4efff;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 28px;
  padding-right: 4px;
  width: fit-content;
}

.file svg {
  cursor: pointer;
}

.del {
  display: flex;
  align-items: center;
}

.fileName {
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex-grow: 1;
}
