.title {
  margin-bottom: 32px;
  color: #284775;
  font-family: "MontBold";
  font-size: 24px;
}

.form {
  display: grid;
  flex-direction: column;
  gap: 20px;
}

.formTitle {
  color: #284775;
  font-family: "MontSemiBold";
  font-size: 20px;
}
