.cell {
    text-align: left;
    padding: 16px 20px;
    white-space: nowrap;
    color: var(--color-black);
}

.transparent {
    background: transparent;
}

.white {
    background: var(--color-white);
}

.red {
    background: var(--color-red);
}

.green {
    background: var(--color-green);
}

.blue {
    background: var(--color-foreground-gray);
}

.inherit {
    background: inherit;
}
